export const errorPage: any = {
  patMainMenu: "Main Navigational Menu Consumer Retina Brazil (PT)",
  patFooterMenu: "Footer Navigation Menu Consumer Retina Brazil (PT)",
  patFooter: "Footer - Consumer - Retina Brazil (PT)",
  exitPopup: "Exit Popup Brazil (PT)",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Retina Brazil (PT)",
  patTopNav: "Top Navigation Menu Retina Brazil (PT)",
  patSiteLogo: "Site logos - Retina Brazil (PT)",
  socialBlock: "Footer Social Icons",
  patSitemapLabel: "DTC Sitemap - Retina Brazil (PT)",
  banner: "Banner - HTML - Error Page - DTC - Brazil (PT)",
  subhead: "Subhead error page - Retina Brazil (PT)",
  footerClassName: "common-footer error-footer",
  bodyClassName: "error-page",
  redirectLangUrl: {
    redirectUrl: {
      "en": "/error",
      "fr": "/fr/error",
    }
  }
}
